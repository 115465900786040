import { useAuthStore } from '@/store/auth';

function handleRedirectQuery(redirectPath: string | undefined) {
    if (!redirectPath) {
        return '/start';
    }
    if (redirectPath.startsWith('https://www.meetselect.com')) {
        window.location.replace(redirectPath);
    } else if (redirectPath.startsWith('http')) {
        return '/start';
    } else {
        return redirectPath;
    }
}

export default defineNuxtRouteMiddleware((to) => {
    const authStore = useAuthStore();
    const authCookie = useAuthCookie();
    console.log(
        'Heading to',
        to.path,
        'but I think we should go somewhere else...',
    );
    if (!authCookie) {
        return;
    }
    const token = authCookie.accessToken;
    authStore.authenticate(!!token);
    authStore.parseJWT(token);
    if (!authStore.isAuthenticated || !authStore.isActive) {
        return;
    }
    if (authStore.isSubscription) {
        const redirectPath = to.query.redirect as string;
        return handleRedirectQuery(redirectPath);
    } else {
        return {
            path: '/signup/plans',
            query: {
                redirect: to.query.redirect,
            },
        };
    }
});
